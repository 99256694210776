import styled from '@emotion/styled';
import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import ReactPlayer from 'react-player';
import Container from './Homepage/Container';

interface VideoProps {
  videoSrcURL: string;
  videoTitle?: string;
  videoBackgroundImage?: string;
  playing?: boolean;
  videoContainerStyle?: CSSProperties;
}

const BORDER_RADIUS = 25;

const VideoBackground = styled.div<{ videoBackgroundImage: string }>(
  ({ videoBackgroundImage }) => ({
    backgroundImage: `url(${videoBackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  })
);

const VideoPlayer: FC<VideoProps> = ({
  videoSrcURL,
  videoTitle,
  videoBackgroundImage,
  playing = false,
  videoContainerStyle,
  ...props
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && typeof IntersectionObserver !== 'undefined') {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const video = entry.target as HTMLIFrameElement;
              video.src = video.dataset.src || '';
              observer.unobserve(video);
            }
          });
        },
        {
          rootMargin: '0px',
          threshold: 0.5,
        }
      );

      observer.observe(videoRef.current);
    }
  }, []);

  useEffect(() => {
    const handleWindowLoad = () => {
      if (videoRef.current) {
        const video = videoRef.current;
        video.src = video.dataset.src || '';
      }
    };

    window.addEventListener('load', handleWindowLoad);

    return () => {
      window.removeEventListener('load', handleWindowLoad);
    };
  }, []);

  const handleResize = useCallback(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const { clientWidth } = videoElement.parentElement;
      const aspectRatio = 16 / 9;

      const height = clientWidth / aspectRatio;
      videoElement.style.height = `${height}px`;
    }
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <div
      ref={videoRef}
      css={{
        position: 'relative',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <ReactPlayer
        playing={playing}
        css={{
          iframe: {
            borderRadius: BORDER_RADIUS,
          },
        }}
        title={videoTitle}
        width={!!videoBackgroundImage ? '75%' : '100%'}
        height={!!videoBackgroundImage ? '75%' : '100%'}
        url={videoSrcURL}
        controls
        config={{
          youtube: {
            playerVars: { showinfo: 1 },
          },
        }}
        {...props}
      />
    </div>
  );
};

const Video: FC<VideoProps> = ({
  videoBackgroundImage,
  videoSrcURL,
  videoTitle,
  playing = false,
  videoContainerStyle,
}) => {
  return (
    <Container
      css={{
        paddingLeft: 0,
        paddingRight: 0,
        maxWidth: 900,
        ...videoContainerStyle,
      }}
    >
      <VideoBackground videoBackgroundImage={videoBackgroundImage}>
        <VideoPlayer
          videoSrcURL={videoSrcURL}
          videoTitle={videoTitle}
          videoBackgroundImage={videoBackgroundImage}
          videoContainerStyle={videoContainerStyle}
          playing={playing}
        />
      </VideoBackground>
    </Container>
  );
};

export default Video;
